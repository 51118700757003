/**
 * pin the modal to the left panel
 */
if (!lemonade && typeof (require) === 'function') {
    var lemonade = require('lemonadejs');
}

;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    global.Modal = factory();
}(this, (function () {

    // References
    const modals = [];
    // State of the resize and move modal
    let state = {};
    // Internal controls of the action of resize and move
    let controls = {};
    // Width of the border
    let cornerSize = 10;
    // Container with minimized modals
    const minimizedModals = [];
    // Default z-index for the modals
    const defaultZIndex = 20;

    /**
     * Send the modal to the front
     * @param container
     */
    const sendToFront = function(container) {
        let highestXIndex = defaultZIndex;
        for (let i = 0; i < modals.length; i++) {
            const zIndex = parseInt(modals[i].el.style.zIndex);
            if (zIndex > highestXIndex) {
                highestXIndex = zIndex;
            }
        }
        container.style.zIndex = highestXIndex + 1;
    }

    /**
     * Send modal to the back
     * @param container
     */
    const sendToBack = function(container) {
        container.style.zIndex = defaultZIndex;
    }

    // Get the coordinates of the action
    const getCoords = function(e) {
        let x;
        let y;

        if (e.changedTouches && e.changedTouches[0]) {
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        } else {
            x = e.clientX;
            y = e.clientY;
        }

        return [x,y];
    }

    // Get the button status
    const getButton = function(e) {
        e = e || window.event;
        if (e.buttons) {
            return e.buttons;
        } else if (e.button) {
            return e.button;
        } else {
            return e.which;
        }
    }

    // Finalize any potential action
    const mouseUp = function(e) {
        // Finalize all actions
        if (typeof(controls.action) === 'function') {
            controls.action();
        }
        setTimeout(function() {
            // Remove cursor
            if (controls.e) {
                controls.e.style.cursor = '';
            }
            // Reset controls
            controls = {};
            // Reset state controls
            state = {
                x: null,
                y: null,
            }
        }, 0)
    }

    const mouseMove = function(e) {
        if (! getButton(e)) {
            return false;
        }
        // Get mouse coordinates
        let [x,y] = getCoords(e);

        // Move modal
        if (controls.type === 'move') {
            if (state && state.x == null && state.y == null) {
                state.x = x;
                state.y = y;
            }

            let dx = x - state.x;
            let dy = y - state.y;
            let top = controls.e.offsetTop + dy;
            let left = controls.e.offsetLeft + dx;

            // Update position
            controls.top = top;
            controls.left = left;
            controls.e.style.top = top + 'px';
            controls.e.style.left = left + 'px';

            state.x = x;
            state.y = y;
            state.top = top
            state.left = left
        } else if (controls.type === 'resize') {
            let width = null;
            let height = null;
            let newHeight = null;

            if (controls.d === 'e-resize' || controls.d === 'ne-resize' || controls.d === 'se-resize') {
                // Update width
                width = controls.w + (x - controls.x);
                controls.e.style.width = width + 'px';

                // Update Height
                if (e.shiftKey) {
                    newHeight = (x - controls.x) * (controls.h / controls.w);
                    height = controls.h + newHeight;
                    controls.e.style.height = height + 'px';
                } else {
                    newHeight = false;
                }
            }

            if (! newHeight) {
                if (controls.d === 's-resize' || controls.d === 'se-resize' || controls.d === 'sw-resize') {
                    height = controls.h + (y - controls.y);
                    controls.e.style.height = height + 'px';
                }
            }
        }
    }

    document.addEventListener('mouseup', mouseUp);
    document.addEventListener('mousemove', mouseMove);

    const isTrue = function(e) {
        return e === true || e === 1 || e === 'true';
    }

    // Dispatcher
    const Dispatch = function(type, option){
        if (typeof this[type] === 'function') {
            this[type](this, option)
        }
    }

    const refreshMinimized = function() {
        let items = minimizedModals;
        let numOfItems = items.length;
        let width = 10;
        let height = 55;
        let offsetWidth = window.innerWidth;
        let offsetHeight = window.innerHeight;
        for (let i = 0; i < numOfItems; i++) {
            let item = items[i];
            item.el.style.left = width + 'px';
            item.el.style.top = offsetHeight - height + 'px';
            width += 205;

            if (offsetWidth - width < 205) {
                width = 10;
                height += 50;
            }
        }
    }

    const delayAction = function(self, action) {
        // Make sure to remove the transformation before minimize to preserve the animation
        if (self.el.style.marginLeft || self.el.style.marginTop) {
            // Make sure no animation during this process
            self.el.classList.add('action');
            // Remove adjustment
            removeMargin(self);
            // Make sure to continue with minimize
            setTimeout(function() {
                // Remove class
                self.el.classList.remove('action');
                // Call action
                action(self);
            },0)

            return true;
        }
    }

    const setMini = function(self) {
        if (delayAction(self, setMini)) {
            return;
        }

        // Minimize modals
        minimizedModals.push(self);

        self.el.top = self.el.offsetTop;
        self.el.left = self.el.offsetLeft;

        if (! self.el.style.top) {
            self.el.style.top = self.el.top + 'px';
        }
        if (! self.el.style.left) {
            self.el.style.left = self.el.left + 'px';
        }

        self.el.translateY = 0;
        self.el.translateX = 0;

        // Refresh positions
        setTimeout(function() {
            refreshMinimized();
            self.minimized = true;
        },10)
    }

    const removeMini = function(self) {
        minimizedModals.splice(minimizedModals.indexOf(self), 1);
        self.minimized = false;
        self.el.style.top = self.el.top + 'px';
        self.el.style.left = self.el.left + 'px';
        // Refresh positions
        setTimeout(() => {
            refreshMinimized();
        }, 10);
        // Refresh positions
        setTimeout(() => {
            if (self.top === '') {
                self.el.style.top = '';
            }
            if (self.left === '') {
                self.el.style.left = '';
            }
        }, 400);
    }

    const removeMargin = function(self) {
        if (self.el.style.marginLeft) {
            let y = self.el.offsetLeft;
            self.el.style.marginLeft = '';
            self.left = y;
        }

        if (self.el.style.marginTop) {
            let x = self.el.offsetTop;
            self.el.style.marginTop = '';
            self.top = x;
        }
    }

    const adjustHorizontal = function(self) {
        if (! isTrue(self['auto-adjust'])) {
            return false;
        }

        self.el.style.marginLeft = '';
        let viewportWidth = window.innerWidth;
        let margin = 10;

        if (self.position) {
            if (self.position === 'absolute') {
                viewportWidth = document.documentElement.offsetWidth;
            } else if (self.position !== 'center') {
                margin = 0;
            }
        }

        let rightEdgeDistance = viewportWidth - (self.el.offsetLeft + self.el.offsetWidth);
        let transformX = 0;

        if (self.position === 'absolute') {
            if (rightEdgeDistance < 5) {
                transformX = (-1 * self.el.offsetWidth) - margin;
            }
        } else {
            if (rightEdgeDistance < 0) {
                transformX = rightEdgeDistance - margin;
            }
        }

        if (self.el.offsetLeft < 0) {
            transformX = margin - self.el.offsetLeft;
        }
        if (transformX !== 0) {
            self.el.style.marginLeft = transformX + 'px';
        }
    }

    const adjustVertical = function(self) {
        if (! isTrue(self['auto-adjust'])) {
            return false;
        }

        self.el.style.marginTop = '';
        let viewportHeight = window.innerHeight;
        let margin = 10;

        if (self.position) {
            if (self.position === 'absolute') {
                viewportHeight = document.documentElement.offsetHeight;
            } else if (self.position !== 'center') {
                margin = 0;
            }
        }

        let bottomEdgeDistance = viewportHeight - (self.el.offsetTop + self.el.offsetHeight);
        let transformY = 0;

        if (self.position === 'absolute') {
            if (bottomEdgeDistance < 5) {
                transformY = (-1 * self.el.offsetHeight) - margin;
            }
        } else {
            if (bottomEdgeDistance < 0) {
                transformY = bottomEdgeDistance - margin;
            }
        }

        if (self.el.offsetTop < 0) {
            transformY = margin - self.el.offsetTop;
        }
        if (transformY !== 0) {
            self.el.style.marginTop = transformY + 'px';
        }
    }

    const Modal = function (template) {
        let self = this;
        let backdrop = null;
        // Make sure keep the state as boolean
        self.closed = !!self.closed;

        // Keep all modals references
        modals.push(self);

        self.back = function() {
            sendToBack(self.el);
        }
        self.front = function() {
            sendToFront(self.el);
        }

        // Onload method
        let onload = self.onload;

        // Native lemonade
        self.onload = function() {
            if (self.url) {
                fetch(self.url)
                    .then(response => response.clone().body)
                    .then(body => {
                        let reader = body.getReader();
                        reader.read().then(function pump({done, value}) {
                            const decoder = new TextDecoder();
                            template += decoder.decode(value.buffer);
                        });
                    });
            }

            // Dimensions
            if (self.width) {
                self.el.style.width = self.width + 'px';
            }
            if (self.height) {
                self.el.style.height = self.height + 'px';
            }
            // Position
            if (self.top) {
                self.el.style.top = self.top + 'px';
            }
            if (self.left) {
                self.el.style.left = self.left + 'px';
            }

            if (self.position === 'absolute' || self.position === 'right' || self.position === 'bottom' || self.position === 'left') {

            } else {
                if (!self.width && self.el.offsetWidth) {
                    self.width = self.el.offsetWidth;
                }
                if (!self.height && self.el.offsetHeight) {
                    self.height = self.el.offsetHeight;
                }

                // Initial centralize
                if (self.position === 'center' || !self.top) {
                    self.top = (window.innerHeight - self.height) / 2;
                }
                if (self.position === 'center' || !self.left) {
                    self.left = (window.innerWidth - self.width) / 2;
                }

                // Responsive
                if (document.documentElement.clientWidth < 800) {
                        // Full screen
                    if (self.height > 300) {
                        self.el.classList.add('fullscreen');
                    }
                }
            }

            // Auto adjust
            adjustHorizontal(self);
            adjustVertical(self);

            // Backdrop
            if (self.backdrop === true) {
                backdrop = document.createElement('div');
                backdrop.classList.add('lm-modal-backdrop');
                backdrop.addEventListener('mousedown', function() {
                    self.closed = true;
                })
            }

            // Bring to front on focus
            if (self.layers !== false) {
                self.el.classList.add('lm-modal-layers');
            }

            // Focus out of the component
            self.el.addEventListener('focusout', function(e) {
                if (isTrue(self['auto-close'])) {
                    if (! self.el.contains(e.relatedTarget)) {
                        self.closed = true;
                    }
                }
            });

            // Close and stop propagation
            self.el.addEventListener('keydown', (e) => {
                if (e.key === 'Escape' && self.closed === false) {
                    self.closed = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            });

            if (typeof(onload) === 'function') {
                Dispatch.call(self, 'onload', self);
            }
        }

        let ignoreEvents = false;

        self.onchange = function(property) {
            if (ignoreEvents) {
                return false;
            }

            if (property === 'closed') {
                if (self.closed === false) {
                    // Focus on the modal
                    if (self.focus !== false) {
                        self.el.focus();
                    }
                    // Show backdrop
                    if (backdrop) {
                        self.el.parentNode.insertBefore(backdrop, self.el);
                    }

                    // Auto adjust
                    adjustHorizontal(self);
                    adjustVertical(self);
                } else {
                    // Hide backdrop
                    if (backdrop) {
                        backdrop.remove();
                    }
                }

                // Call the vents
                self.closed ? Dispatch.call(self,'onclose',self) : Dispatch.call(self,'onopen',self);
            } else if (property === 'top' || property === 'left' || property === 'width' || property === 'height') {
                if (self[property] !== '') {
                    self.el.style[property] = self[property] + 'px';
                } else {
                    self.el.style[property] = '';
                }

                if (property === 'top') {
                    adjustVertical(self);
                }
                if (property === 'left') {
                    adjustHorizontal(self);
                }
            } else if (property === 'position') {
                if (self.position) {
                    if (self.position === 'center') {
                        self.top = (window.innerHeight - self.el.offsetHeight) / 2;
                        self.left = (window.innerWidth - self.el.offsetWidth) / 2;
                    } else {
                        self.top = '';
                        self.left = '';
                    }
                } else {
                    if (! self.top) {
                        self.top = (window.innerHeight - self.el.offsetHeight) / 2;
                    }
                    if (! self.left) {
                        self.left = (window.innerWidth - self.el.offsetWidth) / 2;
                    }
                }
            }
        }
        
        self.mousemove = function(e) {
            if (getButton(e)) {
                return;
            }

            // Root element of the component
            let item = self.el;
            // Get the position and dimensions
            let rect = item.getBoundingClientRect();

            controls.type = null;
            controls.d = null;
            controls.e = item;
            controls.w = rect.width;
            controls.h = rect.height;

            // When resizable
            if (self.resizable === true) {
                if (rect.height - (e.clientY - rect.top) < cornerSize) {
                    if (rect.width - (e.clientX - rect.left) < cornerSize) {
                        item.style.cursor = 'se-resize';
                    } else {
                        item.style.cursor = 's-resize';
                    }
                } else if (rect.width - (e.clientX - rect.left) < cornerSize) {
                    item.style.cursor = 'e-resize';
                } else {
                    item.style.cursor = '';
                }

                if (item.style.cursor) {
                    controls.type = 'resize';
                    controls.d = item.style.cursor;
                } else {
                    controls.type = null;
                    controls.d = null;
                }
            }
        }

        self.mousedown = function(e) {
            // Get mouse coordinates
            let [x,y] = getCoords(e);
            controls.x = x;
            controls.y = y;
            // Root element of the component
            let item = self.el;
            // Get the position and dimensions
            let rect = item.getBoundingClientRect();

            controls.e = item;
            controls.w = rect.width;
            controls.h = rect.height;

            let corner = (y - rect.top) < 40 && rect.width - (x - rect.left) < 34;

            // Check if the click in on close
            if (isTrue(self.closable)) {
                if (corner) {
                    self.closed = true;

                    if (isTrue(self.minimizable)) {
                        removeMini(self);
                    }
                    return;
                }

                corner = (y - rect.top) < 40 && rect.width - (x - rect.left) < 65;
            }

            // Check if the click in on minimize
            if (isTrue(self.minimizable)) {
                if (corner) {
                    // Handles minimized modal positioning
                    if (self.minimized === true) {
                        removeMini(self);
                    } else {
                        setMini(self);
                    }

                    return;
                }
            }

            if (! self.minimized) {
                // If is not minimized
                if (controls.type === 'resize') {
                    // Make sure the width and height is defined for the modal
                    if (! item.style.width) {
                        item.style.width = controls.w + 'px';
                    }
                    if (! item.style.height) {
                        item.style.height = controls.h + 'px';
                    }
                    // This will be the callback when finalize the resize
                    controls.action = function () {
                        self.width = parseInt(item.style.width);
                        self.height = parseInt(item.style.height);
                        controls.e.classList.remove('action');
                    }
                    controls.e.classList.add('action');
                } else if (isTrue(self.draggable) && self.title && y - rect.top < 40) {
                    // Action
                    controls.type = 'move';
                    // Callback
                    controls.action = function () {
                        self.top = parseInt(item.style.top);
                        self.left = parseInt(item.style.left);

                        controls.e.classList.remove('action');
                    }
                    controls.e.classList.add('action');
                    // Remove transform
                    removeMargin(self);
                }
            }
        }

        self.open = function() {
            if (self.closed === true) {
                self.closed = false;
            }
        }

        self.close = function() {
            if (self.closed === false) {
                self.closed = true;
            }
        }

        return `<div class="lm-modal" animation="{{self.animation}}" position="{{self.position}}" closed="{{self.closed}}" closable="{{self.closable}}" minimizable="{{self.minimizable}}" minimized="{{self.minimized}}" overflow="{{self.overflow}}" :top="self.top" :left="self.left" :width="self.width" :height="self.height" onmousedown="self.mousedown(e)" onmousemove="self.mousemove(e)" tabindex="-1">
            <div class="lm-modal-title" data-icon="{{self.icon}}">{{self.title}}</div>
            <div>${template}</div>
        </div>`
    }

    lemonade.setComponents({ Modal: Modal });

    return function (root, options, template) {
        if (typeof(root) === 'object') {
            if (! template) {
                template = '';
            }
            // Keep the DOM elements
            let elements = [];
            while (root.firstChild) {
                elements.push(root.firstChild);
                root.firstChild.remove();
            }
            // Create the modal
            let e = lemonade.render(Modal, root, options, template);
            // Append any elements inside the modal
            if (elements.length) {
                while (elements[0]) {
                    e.children[1].appendChild(elements[0]);
                    elements.shift();
                }
            }
            return options;
        } else {
            return Modal.call(this, root);
        }
    }
})));